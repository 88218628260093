import { Pipe, PipeTransform } from '@angular/core';

/**
 * Format bytes to show on file upload interface
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point, default is 2)
 * Usage:
 *  bytes | formatBytes:decimals
 *  @example
 *  // returns 1 KB
 *  {{ 1024 | formatBytes }}
 */
@Pipe({
  name: 'formatBytes',
  standalone: true
})
export class FormatBytesPipe implements PipeTransform {
  private readonly sizes = ['bytes', 'KB', 'MB', 'GB'];

  transform(bytes: number, decimals = 2): string {
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + this.sizes[i];
  }
}
